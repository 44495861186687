<template>
  <div class="transfer-detail">
    <el-descriptions title="Your make payment to" size="mini" :column="2" border
    v-if="bankDetails">
      <el-descriptions-item label="Currency">
        {{ bankDetails.currency }}
      </el-descriptions-item>
      <el-descriptions-item label="BankholderID">
        {{ bankDetails.cardholderid }}
      </el-descriptions-item>
      <el-descriptions-item label="Account Name">
        {{ accountName }}
      </el-descriptions-item>
      <el-descriptions-item label="Account No.">
        {{ bankDetails.accountnumber }}
      </el-descriptions-item>
      <el-descriptions-item label="Sort Code">
        {{ bankDetails.sortcode }}
      </el-descriptions-item>
      <el-descriptions-item label="IBAN">
        {{ bankDetails.international.IBAN }}
      </el-descriptions-item>
      <el-descriptions-item label="BIC">
        {{ bankDetails.international.BIC }}
      </el-descriptions-item>
      <el-descriptions-item label="Inter BIC">
        {{ bankDetails.international.correspondent }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="amount-label" content-class-name="amount-content" label="Amount">
        {{ bankDetails.currency}} {{ numberFormat(bankDetails.amount) }}
      </el-descriptions-item>
      <el-descriptions-item label="Reference">
        {{ bankDetails.paymentReference }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Utils from "@/utils";
export default {
  props:{
    bankDetails: {
      type: Object,
      required: true
    }
  },
  computed:{
    accountName() {
      return this.$store.getters.name;
    },
  },
  methods:{
    numberFormat(number){
      return Utils.numberToString(number)
    }
  }
}
</script>

<style lang="scss">
.transfer-detail {
  .amount-label {
    background: #E1F3D8;
  }

  .amount-content {
    background: #FDE2E2;
    font-weight: 700 !important;
  }
}
</style>