<template>
  <div class="transfer-content">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Receiving Money by UK Bank Transfer</h3>
            </div>

            <div v-if="!stepform">
              <div class="card-body min-vh" v-loading="loading">
                <div class="row justify-content-md-center">
                  <div class="col-lg-8 col-md-12">
                    <div class="text-xs mt-4 mb-6">Transfer money from any UK bank account to your A&B PLUS Business Account (GBP) securly with ASPS connetting system</div>
                    <el-form
                      ref="formTransfer"
                      :model="formData"
                      :rules="formRule"
                      :label-position="labelPosition"
                      label-width="150px"
                    >
                      <el-form-item label="BankholderID" prop="bankholderID">
                        <el-select
                          v-model="formData.bankholderID"
                          @change="setCurrency"
                          placeholder="From BankholderID"
                          class="form-control-alternative"
                        >
                          <el-option
                            v-for="item of bankAccount"
                            :key="item.uid"
                            :label="`${item.currency} (${item.cardholderid})`"
                            :value="item.cardholderid"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item label="Amount" prop="amount">
                        <el-input
                          type="number"
                          v-model="formData.amount"
                          class="form-control-alternative"
                        ></el-input>
                      </el-form-item>

                      <el-form-item label="Reference" prop="paymentReference">
                        <el-input
                          type="textarea"
                          minlength="6"
                          v-model="formData.paymentReference"
                          class="form-control-alternative"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row justify-content-xl-center">
                  <div class="col-xl-12 text-center">
                    <el-button
                      type="primary"
                      @click="onContinue()"
                      v-loading="submiting"
                      :disabled="submiting"
                      >Continue <i class="el-icon-arrow-right"></i
                    ></el-button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="stepform === 'aspsps'">
              <div class="card-body min-vh" v-loading="loading">
                <div class="row justify-content-md-center">
                  <div class="col-lg-12 col-md-12">
                    <BankDetails :bank-details="bankDetailData" style="margin-bottom:16px"/>
                    <el-row class="justify-content-md-center">
                      <h4>Select your bank</h4>
                      <el-col
                        :span="isMobile ? 6: 4"
                        v-for="(item) in aspspsBank"
                        :key="item.id"
                      >
                      <div @click="selectAspspsBank(item.id)">
                        <el-card
                          :ref="`bank${item.id}`"
                          :body-style="{ padding: '0px', cursor: 'pointer' }"
                          shadow="hover"
                          :class="`m-1 ${item.id === formData.aspspId ? 'selected' : ''}`" 
                          :title="item.name"
                        >
                        <img :src="item.logo" class="image"/>   
                           
                          <div class="d-none d-sm-none d-md-block text-center" style="padding: 2px; font-size:0.8vw; min-height: 5vw;">
                            <span>{{ item.name }}</span>
                          </div>
             
                        </el-card>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>

              </div>
              <div class="card-footer">
                <div class="row justify-content-xl-center">
                  <div class="col-xl-12 text-center">
                    <el-button @click="stepform = ''"
                      ><i class="el-icon-arrow-left"></i> Back</el-button
                    >
                    <el-button type="primary" @click="onConsent()"
                      >Continue <i class="el-icon-arrow-right"></i
                    ></el-button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="stepform === 'consent'">
              <div class="card-body min-vh">
                <div class="row justify-content-md-center">
                  <div class="col-lg-12 col-md-12">
                    <BankDetails :bank-details="bankDetailData" style="margin-bottom:16px"/>
                    <div>
                      <h4>Payment By</h4>
                      <div class="row g-0 border rounded overflow-hidden flex-md-row m-2 shadow-sm h-md-250 position-relative">
                        <div class="col p-4 d-flex flex-column position-static">
                          <h4 class="mb-0 text-black-50">You bank</h4>
                          <h3 class="mb-0">{{ bankDetailData.bankpayment.name }}</h3>
                        </div>
                        <div class="col-auto d-none d-lg-block">
                          <img :src="bankDetailData.bankpayment.logo" width="100px" />
                        </div>
                      </div>
                      <div class="row g-0 border rounded overflow-hidden flex-md-row m-2 shadow-sm position-relative">
                        <div class="col-12 p-4 ">
                          <h4 class="mb-0">You'll be securely redirected to your bank
                          to authenticate this payment.</h4>
                        </div>
                      </div>
                      <div class="row g-0 border rounded overflow-hidden flex-md-row m-2 shadow-sm position-relative">
                        <div class="col-12 p-4">
                          <p class="text-sm mb-0">
                            By continuing you are permitting our partner Modulr FS
                            Limited to initiate a payment from your bank account and
                            also agree to their <a href="/terms-and-conditions" target="_blank">terms and conditions</a> and <a href="/terms-and-conditions" target="_blank">privacy policy.</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row justify-content-xl-center">
                  <div class="col-xl-12 text-center">
                    <el-button @click="stepform = 'aspsps'"
                      ><i class="el-icon-arrow-left"></i> Back</el-button
                    >
                    <el-button
                      type="primary"
                      @click="onSubmit()"
                      v-loading="submiting"
                      :disabled="submiting"
                      >Confirm</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <Shortcut />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils";
import BankApi from "@/api/bankaccount";
import { isMobile } from "@/utils/helpers";
import Shortcut from "@/components/MenuShortcut.vue";
import BankDetails from "./components/BankDetails.vue";
import BankList from "./components/banklist";
export default {
  components: {
    Shortcut,
    BankDetails
  },
  computed: {
    isBusiness() {
      return this.$store.getters.business;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    }
  },
  data() {
    return {
      stepform: "",
      submiting: false,
      loading: false,
      bankAccount: [],
      aspspsBank: [],
      formData: {
        currency: "",
        amount: 0,
        bankholderID: "",
        paymentReference: "",
        aspspId: "",
      },
      bankDetailData: {},
      formRule: {
        bankholderID: [{
          required: true,
          message: "Please select BankholderID",
          trigger: "blur",
        }, ],
        amount: [{
          required: true,
          message: "Please input amount",
          trigger: "blur",
        },{
          min: 1,
          message: "Amount must be greater than 0",
          trigger: "blur",
        }],

        paymentReference: [{
          min: 6,
          message: "Payment Reference must be at least 6 characters",
        },{
          required: true,
          message: "Please enter reference",
          trigger: "blur",
        }, ],
      },
      bankList: BankList.items,
    };
  },
  methods: {
    async getBankAccount() {
      this.loading = true
      BankApi.getMultiAccount().then(({result, data, message})=>{
        this.loading = false
        if(result){
          this.bankAccount = data['GBP']
        }else{
          this.$message.error(message)
        }
      })
    },
    setCurrency() {
      this.setBank();
      if (this.$route.query.bankholderid) {
        this.$router.push(this.$route.path);
      }
    },
    setBank(){
      const bankData = Utils.findObject(
        this.bankAccount,
        this.formData.bankholderID,
        "cardholderid"
      );
      this.bankDetailData = bankData;
      this.bankDetailData['amount'] = this.formData.amount;
      this.bankDetailData['paymentReference'] = this.formData.paymentReference;
      this.formData.currency = bankData.currency;

    },
    getSingleImmediate(data) {
      let output = [];
      data.forEach((item) => {
        const media = Utils.findObject(this.bankList, item.id, "id");
        const capabilities = Utils.findObject(
          item.capabilities,
          "SINGLE_IMMEDIATE",
          "type"
        );
        if (capabilities.status === "ENABLED") {
          item.logo = media.logo;
          output.push(item);
        }
      });

      return output;
    },
    selectAspspsBank(aspspId) {
      this.formData.aspspId = aspspId;
      const bankPayment = Utils.findObject(this.bankList, aspspId, "id");
      this.bankDetailData['bankpayment'] = bankPayment;
    },
    onContinue() {
      this.$refs.formTransfer.validate((valid) => {
        if(valid) {
          this.submiting = true;
          BankApi.getASPSPSBank().then(({ result, data, message }) => {
            this.submiting = false;
            if (result) {
              this.aspspsBank = this.getSingleImmediate(data);
              this.setBank();
              this.stepform = "aspsps";
            } else {
              this.$message.error(message);
            }
          });
        }
      });

    },
    onConsent() {
      if(!this.formData.aspspId){
        this.$swal("Message", "Please select your bank", "error");
        return false;
      }
      this.stepform = "consent";
    },
    onSubmit() {
      this.submiting = true;
      let data = JSON.parse(JSON.stringify(this.formData));
      data.amount = parseFloat(this.formData.amount);
      BankApi.postPosPayment(data).then(({ result, data, message }) => {
        this.submiting = false;
        if(result){
          window.location.href = data.redirectUrl;
        }else{
          this.$swal("Message", message, "error");
        }
      })
    },
  },
  async mounted() {
    await this.getBankAccount();
    if (this.$route.query.bankholderid) {
      this.formData.bankholderID = this.$route.query.bankholderid;
      this.setCurrency();
    }
  },
};
</script>

<style lang="scss">
.transfer-content {
  .image {
    width: 80%;
    display: block;
    margin: 5px auto;
  }
  .selected{
    background-color: #f5f5f5;
    border-color: #b5b5b5;
    box-shadow: #dfdfdf 0px 0px 9px 2px;
    -moz-box-shadow: #dfdfdf 0px 0px 9px 2px;
  }

  .el-descriptions-item__label.is-bordered-label {
    width: 110px;
  }

}
</style>